import { useCallback, useEffect, useState } from 'react'
import { useRouter } from 'next/router'

import Img from 'components/ImageFadeIn'
import { StandardPage } from 'components/Layout'
import StoreList from 'components/pages/explore/stores/StoreList'

import { styled } from 'styles/stitches.config'

import useEventErrorHandler from 'hooks/useEventErrorHandler'

import type { StoreLocation, StoreLocationsResponse } from 'types/api'
import { StoreByDistance } from 'types/api/store'

const ImageBanner = styled('div', {
  backgroundColor: '$white',
  padding: '$s',
  textAlign: 'center',
})

const Description = styled('div', {
  backgroundColor: '$white',
  fontSize: '$body1',
  lineHeight: '$body1',
  padding: '$s',
})

const Locations = styled('div', {
  padding: '$s',
})

type Company = Pick<StoreLocation, 'color' | 'desc' | 'logo' | 'title'>
type Store = Pick<
  StoreLocation,
  | 'address'
  | 'city'
  | 'close'
  | 'id'
  | 'image'
  | 'name'
  | 'online'
  | 'open'
  | 'published'
  | 'wait'
> & {
  curbsideAvailable: boolean
  postalCode: string
}
type Stores = Store[]

const mapStoreLocationResponseToStoresList = (stores: StoreLocationsResponse) =>
  stores.map((store) => {
    const {
      address,
      city,
      close,
      curb: curbsideAvailable,
      id,
      image,
      name,
      online,
      open,
      published,
      wait,
      zip: postalCode,
    } = store

    return {
      address,
      city,
      close,
      curbsideAvailable,
      id,
      image,
      name,
      online,
      open,
      postalCode,
      published,
      wait,
    }
  })

const CompanyByName = ({ data }: { data: StoreLocationsResponse }) => {
  const [company, setCompany] = useState<Company | undefined>()
  const [stores, setStores] = useState<Stores>([])

  const { handleError } = useEventErrorHandler()
  const router = useRouter()

  const {
    query: { companyName },
  } = router

  const redirectToStoresList = useCallback(() => {
    router.replace({
      pathname: '/explore/stores',
    })
  }, [router])

  const shouldRedirectToStoresList = useCallback(() => {
    const companyNamesToBypass = [
      'apple-app-site-association', // adbots hit this sometimes
      'stores',
    ]

    return companyNamesToBypass.indexOf(companyName as string) >= 0
  }, [companyName])

  useEffect(() => {
    if (shouldRedirectToStoresList()) {
      redirectToStoresList()
      return
    }

    const publishingStores = data.filter(({ published }) => published)

    if (publishingStores.length === 1) {
      // only have 1 store
      router.replace(`/explore/stores/${publishingStores[0].id}`)
      return
    }

    if (publishingStores.length > 1) {
      const { color, desc, logo, title } = publishingStores[0]

      setCompany({ color, desc, logo, title })
      setStores(mapStoreLocationResponseToStoresList(publishingStores))
      return
    }

    redirectToStoresList()
  }, [
    companyName,
    data,
    handleError,
    redirectToStoresList,
    router,
    shouldRedirectToStoresList,
  ])

  if (!data || data.length === 0 || shouldRedirectToStoresList()) {
    return null
  }

  return (
    <StandardPage>
      <ImageBanner css={{ backgroundColor: company?.color || 'transparent' }}>
        <Img alt={`${company?.title} logo image`} center src={company?.logo} />
      </ImageBanner>
      <Description>{company?.desc}</Description>
      <Locations>
        <StoreList stores={stores as unknown as StoreByDistance[]} />
      </Locations>
    </StandardPage>
  )
}

export default CompanyByName
