import { keyframes } from '@stitches/react'
import { styled } from 'styles/stitches.config'

const gradientWidth = 256

const load = keyframes({
  '0%': { left: -gradientWidth },
  '100%': { left: '100%' },
})

export const SkeletonSet = styled('div', {
  boxSizing: 'content-box',
  display: 'flex',
  flexWrap: 'wrap',
  margin: '0 -$xs',
})

export const Skeleton = styled('div', {
  flex: '1 0 auto',
  padding: '0 $xs',
  width: '100%',
})

export const Bone = styled('div', {
  backgroundColor: '$grey100',
  border: '1px solid $grey200',
  borderRadius: 4,
  display: 'block',
  height: '1rem',
  margin: '0 0 $xs',
  overflow: 'hidden',
  position: 'relative',

  ['&::before']: {
    animation: `${load} 1s cubic-bezier(0.4, 0.0, 0.2, 1) infinite`,
    background: 'linear-gradient(to right, transparent 0%, $grey300 50%, transparent 100%)',
    content: '',
    display: 'block',
    height: '100%',
    left: -gradientWidth,
    position: 'absolute',
    top: 0,
    width: gradientWidth,
  },

  variants: {
    input: {
      true: {
        borderRadius: '$xxs',
        height: '44px',
        marginBottom: '$s',
      },
    },
    label: {
      true: {
        height: 'var(--joe-lineHeights-body1)',
        marginTop: '$s',
        marginBottom: '$xs',
        width: '75%',
      },
    },
  },
})
