import type { GetServerSidePropsContext } from 'next'
import type { StoreLocationsResponse } from 'types/api'

import { api } from 'config/apiClient'
import Screen from 'screens/companyName'
import { CompanyMapping } from 'config/company'

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const cookie = context.req.headers?.cookie

  const fixedCompanyName = CompanyMapping[context?.query?.companyName as string]

  if (fixedCompanyName) {
    return {
      redirect: {
        destination: `/company/${fixedCompanyName}`
      }
    }
  }

  const { data } = await api('getStoreLocations', {
    companyName: (context?.query?.companyName as string).trim(),
  }, cookie)

  return {
    props: {
      data,
    },
  }
}

const CompanyName = ({ data }: {
  data: StoreLocationsResponse
}) => {
  return (
    <Screen data={[...data]} />
  )
}

export default CompanyName
