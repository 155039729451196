/* Services */
import { logError } from 'utils/honeybadger'

/* Config */
import { CLOUDINARY_OPTIONS } from 'config/cloudinarySettings'

interface CloudinaryImage {
  id: string
  url: string
  height: number
  width: number
  format: string
}

type Format = 'auto' | 'png' | 'jpeg' | 'webp'

export const cloudinaryDomain = 'https://res.cloudinary.com'
const assetVersion = 'v1'

export const handleResultCallback = (
  error: CloudinaryUploadError | undefined,
  result: CloudinaryCallbackResult,
  onSuccess?: (image: CloudinaryImage) => void
): CloudinaryImage | undefined => {
  if (error) {
    if (error.statusText === 'abort') {
      return
    }

    if (
      /Image dimensions \(.+\) are smaller than the minimum required: \(400X400\)/.exec(
        error.statusText
      )
    ) {
      return
    }

    logError(new Error('Cloudinary Error'), { ...error })
    return
  }

  if (result) {
    if (result.event === 'close') {
      return
    } else if (result.event === 'success' && result.info) {
      const info = result.info

      onSuccess?.({
        id: info.public_id,
        url: info.secure_url,
        width: info.width,
        height: info.height,
        format: info.format,
      } as CloudinaryImage)
    }
  }
}

export const uploadImageToCloudinary = (
  onSuccess?: (image: CloudinaryImage) => unknown
) => {
  if (typeof window !== 'undefined' && window.cloudinary) {
    return window.cloudinary.createUploadWidget(
      CLOUDINARY_OPTIONS,
      (error, result) => handleResultCallback(error, result, onSuccess)
    )
  }
}

/**
 * Reformats images uploaded on Cloudinary (i.e cat.jpg -> cat.webp)
 * @param imgUrl The Cloudinary starting with https://res.cloudinary.com
 * @param options For now, it's an object containing format (default to 'auto') option
 * @returns
 */
export const formatCloudinaryImage = (
  imgUrl: string,
  { format = 'auto' }: { format?: Format }
): string => {
  if (!imgUrl.includes(cloudinaryDomain)) {
    return imgUrl
  }

  // Once split, options, which are separated by commas, should be at index BEFORE asset version
  // ['joe-coffee', 'image', 'upload', 'options_here', 'v1', ...]
  const components = imgUrl.replace(`${cloudinaryDomain}/`, '').split('/')

  const optionIndex =
    components.findIndex((value) => value === assetVersion) - 1

  const optionString = components[optionIndex] || ''

  let newOption = ''

  const fileFormatRegex = new RegExp(/(f_)\w+/, 'g') // Matches the format option (i.e 'f_auto')

  // Reformat if already specify format
  if (fileFormatRegex.test(optionString)) {
    newOption = optionString.replace(fileFormatRegex, `f_${format}`)
  } else {
    // Add the format
    newOption = optionString.concat(`/f_${format}`)
  }

  // Re-assemble the array
  components[optionIndex] = newOption
  components.unshift(cloudinaryDomain)

  return components.join('/')
}
