import { Aura, Pin } from 'components/DesignSystemIcons'
import { MouseEventHandler, useRef, useState } from 'react'
import Router from 'next/router'
import useProfile from 'hooks/useProfile'
import { api } from 'config/apiClient'
import { styled } from 'styles/stitches.config'
import useEventErrorHandler from 'hooks/useEventErrorHandler'

const BUTTON_SIZE = 40

export const FavoriteIconAura = styled(Aura, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: BUTTON_SIZE,
  height: BUTTON_SIZE,

  boxShadow: '-2px 4px 20px rgba(0, 0, 0, 0.1)',

  background: '$white',
  variants: {
    filled: {
      true: {
        background: '$red500',
        color: '$white'
      }
    },
    small: {
      true: {
        width: 20,
        height: 20,
        padding: '$xxxs',
      }
    }
  }
})

interface Props {
  storeId: string
  like?: boolean
}

const FavoriteButton = ({ like: initLike = false, storeId }: Props) => {
  const [like, setLike] = useState(initLike)
  const [profile] = useProfile()
  const loading = useRef(false)

  const { handleError } = useEventErrorHandler()

  const onClickLike: MouseEventHandler = async (event) => {
    event.stopPropagation()

    if (!profile) {
      Router.push('/sign-in/getting-started')
      return
    }

    if (loading.current) {
      return
    }

    loading.current = true
    setLike(true)

    try {
      const res = await api('likeStore', { storeId })

      if (res.success) {
        loading.current = false
        return
      }
    } catch (error) {
      handleError(error)
    }

    loading.current = false
    setLike(false)
  }

  const onClickUnLike: MouseEventHandler = async (event) => {
    event.stopPropagation()

    if (loading.current) {
      return
    }

    loading.current = true
    setLike(false)

    try {
      const res = await api('unlikeStore', { storeId })

      if (res.success) {
        loading.current = false
        return
      }
    } catch (error) {
      handleError(error)
    }

    loading.current = false
    setLike(true)
  }

  if (like) {
    return (
      <FavoriteIconAura
        filled
        onClick={onClickUnLike}
        data-testid="unlike-button"
      >
        <Pin size={20} strokeWidth={0} />
      </FavoriteIconAura>
    )
  }

  return (
    <FavoriteIconAura
      onClick={onClickLike}
      data-testid="like-button"
    >
      <Pin size={20} />
    </FavoriteIconAura>
  )
}

export default FavoriteButton
