import { styled } from 'styles/stitches.config'
import LazyLoad from 'react-lazyload'
import { formatCloudinaryImage } from 'services/Cloudinary'

import type * as Stitches from '@stitches/react'

const ImageContainer = styled('div', {
  position: 'relative',
  '&::before': {
    content: '',
    display: 'block',
  }
})

const Img = styled('img', {
  height: '100%',
  left: 0,
  objectFit: 'cover',
  position: 'absolute',
  top: 0,
  width: '100%',
})

export interface Props extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  alt: string
  aspectRatio?: number
  css?: Stitches.CSS
  height?: number | string
  src?: string
  width?: number | string
}

const ImageWithAspectRatio = ({
  alt, aspectRatio = 40, css, height, src, width,
}: Props) => (
  <ImageContainer
    css={{
      ...css,

      '&::before': {
        paddingTop: `${aspectRatio}%`,
      }
    }}
  >
    <LazyLoad offset={1024} once>
      <Img
        alt={alt}
        css={css}
        data-testid="image-with-aspect-ratio"
        height={height}
        src={formatCloudinaryImage(src || 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=', {})}
        width={width}
      />
    </LazyLoad>
  </ImageContainer>
)

export default ImageWithAspectRatio
