import Router from 'next/router'
import StoreInfo from 'components/StoreInfo'

import { styled } from 'styles/stitches.config'

import type { StoreByDistance } from 'types/api/store'

const Anchor = styled('a', {
  borderRadius: '$s',
  boxShadow: '-2px 4px 20px rgba(0, 0, 0, 0.1)',
  display: 'block',
  overflow: 'hidden',

  '&:last-child': {
    marginBottom: 0,
  }
})

type StoreCardProps = {
  store: StoreByDistance
  showLike?: boolean
}

const StoreCard = ({ store, showLike }: StoreCardProps) => {
  const onClick = () => {
    Router.push({
      pathname: '/explore/stores/[storeId]',
      query: {
        storeId: store.id
      },
    })
  }

  return (
    <div onClick={onClick}>
      <Anchor aria-label="store-card">
        <StoreInfo store={store} showLike={showLike} />
      </Anchor>
    </div>
  )
}

export default StoreCard
