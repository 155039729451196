
import { formatCloudinaryImage } from 'services/Cloudinary'
import ImageWithAspectRatio, { Props as ImageWithAspectRatioProps} from 'components/ImageWithAspectRatio'
import LazyLoad from 'react-lazyload'
import { styled } from 'styles/stitches.config'

const ImgElement = styled('img', {})

const ImageContainer = styled('div', {
  transition: '$opacityQuick',

  variants: {
    center: {
      true: {
        [`${ImgElement}`]: {
          margin: '0 auto !important',
        }
      },
    },
  },
})

type Props = ImageWithAspectRatioProps & { center?: boolean }

const Img = ({
  alt, aspectRatio, center, css, height, src, width,
}: Props) => {
  return (
    <ImageContainer center={center}>
      {aspectRatio ? (
        <ImageWithAspectRatio
          alt={alt}
          aspectRatio={aspectRatio}
          css={css}
          height={height}
          src={formatCloudinaryImage(src || 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=', {})}
          width={width}
        />
      ) : (
        <LazyLoad
          offset={1024}
          once
        >
          <ImgElement
            alt={alt}
            data-testid="image-element"
            height={height}
            src={formatCloudinaryImage(src || 'data:image/gif;base64,R0lGODlhAQABAAAAACwAAAAAAQABAAA=', {})}
            width={width}
          />
        </LazyLoad>
      )}
    </ImageContainer>
  )
}

export default Img
