
/**
 * Taken from the old Consumer Web (Angular app)
 */
 export const CLOUDINARY_OPTIONS: CloudinaryOptions = {
  cloudName: 'joe-coffee',
  uploadPreset: 'jjaewamq_joe_profile_upload',
  sources: ['local'], // , 'facebook', 'instagram'],
  multiple: false,
  maxFiles: 1,
  cropping: true,
  croppingAspectRatio: 1,
  croppingDefaultSelectionRatio: 0.8,
  resourceType: 'image',
  minImageWidth: 400,
  minImageHeight: 400,
  // debug: true,
}
